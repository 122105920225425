import React from 'react'
import HomePage from '../../pages/Home/default'

const HomeLayout = ({ homeData }) => {
  const { controlData } = homeData

  return <HomePage data={{ ...controlData }} />
}

export default React.memo(HomeLayout)
