import React from 'react'
import { OrganizationJsonLd } from 'next-seo'
import Layout from '../src/components/layouts'
import defaultSEO from '../src/common/constants/nextSEO'
import HomeLayout from '../src/components/layouts/HomeLayout'
import CustomJsonLd from '../src/components/shared/CustomJsonLd'
import { APP_BASE_PATH } from '../src/common/constants'
import { data } from '../src/components/pages/Home/consts'
import { jsonLdData } from '../src/common/constants/jsonLd'
import WithSeoOverrides from 'src/components/shared/WithSeoOverride'

export async function getServerSideProps() {
  return {
    props: {
      homeVariation: 'control',
    },
  }
}

const Index = (pageProps) => {
  const { homeVariation } = pageProps

  const JSON_LD_DATA = {
    type: 'Organization',
    id: `${APP_BASE_PATH()}/#organization`,
    logo: `${APP_BASE_PATH()}/next-assets/fdr-logo.webp`,
    legalName: 'Freedom Debt Relief LLC',
    name: 'Freedom Debt Relief',
    address: {
      streetAddress: '1875 S Grant St #400',
      addressLocality: 'San Mateo',
      addressRegion: 'CA',
      postalCode: '94402',
      addressCountry: 'US',
    },
    sameAs: [
      'https://twitter.com/freedomdebt',
      'https://www.facebook.com/freedomdebtrelief/',
      'https://www.linkedin.com/company/freedom-debt-relief/',
      'https://www.youtube.com/freedomdebtrelief',
      'https://www.instagram.com/freedomdebtrelief/',
      'https://www.google.com/maps?cid=1138888979161863326',
    ],
    contactPoints: [
      {
        type: 'ContactPoint',
        telephone: '+18009100065',
        contactType: 'customer service',
      },
    ],
    url: APP_BASE_PATH(),
  }

  return (
    <>
      <WithSeoOverrides {...defaultSEO} />
      <OrganizationJsonLd {...JSON_LD_DATA} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(defaultSEO?.title, defaultSEO?.description, '/')}
      />
      <Layout isBrandPage={true} isHome={true} homeVariation={homeVariation}>
        <HomeLayout
          homeData={{
            controlData: data,
          }}
        />
      </Layout>
    </>
  )
}

export default Index
