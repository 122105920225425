const Star = ({ size, isFilled, filledColor, unfilledColor }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7344 2.77197L15.8244 9.03197L22.7344 10.042L17.7344 14.912L18.9144 21.792L12.7344 18.542L6.55438 21.792L7.73438 14.912L2.73438 10.042L9.64437 9.03197L12.7344 2.77197Z"
      fill={isFilled ? filledColor : unfilledColor}
    />
  </svg>
)

const Rating = ({
  rating,
  totalStars = 5,
  starSize = '24',
  filledStarColor = '#3B6AF7',
  unfilledStarColor = 'transparent',
}) => {
  const getStarFill = (starIndex) => {
    if (rating >= starIndex) {
      return 1
    } else if (rating + 1 > starIndex && rating < starIndex) {
      return rating % 1
    }

    return 0
  }

  return (
    <div className="flex flex-row items-center">
      {[...Array(totalStars)].map((_, index) => {
        const starFill = getStarFill(index + 1)

        return (
          <div
            key={index}
            className="relative"
            style={{ width: 24, height: 24 }}
          >
            <Star
              isFilled={starFill === 1}
              size={starSize}
              filledColor={filledStarColor}
              unfilledColor={unfilledStarColor}
            />
            {starFill > 0 && starFill < 1 && (
              <div
                className="absolute top-0 left-0 h-[100%] overflow-hidden"
                style={{ width: `${starFill * 100}%` }}
              >
                <Star
                  isFilled
                  size={starSize}
                  filledColor={filledStarColor}
                  unfilledColor={unfilledStarColor}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Rating
