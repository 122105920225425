export const featureToggles = {
  BRAND_PAGES_MULTIVARIATE_AB: 'fdr-brand-page-multivariate-test',
  HOME_DATA_VISUALIZATION_AB: 'fdr-home-data-visualization-test',
  ARTICLE_SLIDER_BANNER_AB: 'fdr-article-slider-banner-test',
  HOMEPAGE_SLIDER_CTA: 'fdr-homepage-slider-cta-test',
  HOMEPAGE_HERO_CTA: 'fdr-homepage-hero-cta',
  CLIENT_LOGIN_CTA_ON_MOBILE_TOP_NAV: 'fdr-client-login-cta-on-mobile-top-nav',
  ARTICLES_AND_BLOGS_BOTTOM_CTA: 'fdr-articles-and-blogs-bottom-cta',
  HOME_PAGE_REDESIGN: 'homepage-redesign-multi-variate-test',
  HOMEPAGE_PERSONALIZATION: 'fdr_pzn_test',
  TRUST_STRIPS: 'trust-strips',
  CONSENT_BANNER: 'consent-banner-exp',
  CFOS_DISCLOUSURES: 'fdr-cfos-disclosures',
}

export const featureTogglesValues = {
  'fdr-brand-page-multivariate-test': {
    defaultFeature: '1_control',
  },
  'fdr-homepage-slider-cta-test': {
    defaultFeature: 'control',
  },
  'fdr-home-data-visualization-test': {
    defaultFeature: 'control',
  },
  'fdr-article-slider-banner-test': {
    defaultFeature: 'control',
  },
  'fdr-homepage-hero-cta': {
    defaultFeature: 'control',
  },
  'fdr-client-login-cta-on-mobile-top-nav': {
    defaultFeature: 'control',
  },
  'homepage-redesign-multi-variate-test': {
    defaultFeature: 'control',
  },
  [featureToggles.ARTICLES_AND_BLOGS_BOTTOM_CTA]: {
    defaultFeature: 'control',
  },
  [featureToggles.HOMEPAGE_PERSONALIZATION]: {
    defaultFeature: 'control',
  },
  [featureToggles.TRUST_STRIPS]: {
    defaultFeature: 'control',
  },
  [featureToggles.CONSENT_BANNER]: {
    defaultFeature: 'control',
  },
  [featureToggles.CFOS_DISCLOUSURES]: {
    defaultFeature: 'off',
  },
}
