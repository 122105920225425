import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'
import PlayCircleOutlined from './PlayCircleOutlined'

export default function VideoPlayer({
  url,
  className,
  light,
  thumbnail,
  onClickPreview = () => {},
}) {
  return (
    <ReactPlayer
      light={light}
      width="100%"
      height="100%"
      playIcon={<PlayCircleOutlined className={thumbnail} />}
      className={`relative ${className}`}
      playing
      controls
      url={url}
      style={{ backgroundColor: '#a7a7a7' }}
      onClickPreview={onClickPreview}
    />
  )
}

VideoPlayer.defaultProps = {
  className: '',
  light: true,
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  light: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
