import Image from 'next/image'

import Rating from '../../Rating'
import Typography from '../../Typography'
import { numberFormat } from 'src/common/utils'

const DynamicMarkers = ({
  id,
  image,
  alt,
  rating,
  totalReviews,
  totalStars,
  color,
}) => {
  return (
    <div className="flex w-full flex-col items-center">
      <Image
        src={'https:' + image.fields.file.url}
        alt={alt}
        width={0}
        height={0}
        sizes="100vw"
        style={{
          height: id?.toLowerCase() === 'bbb' ? 80 : 25,
          maxWidth: '100%',
          width: 'auto',
        }}
      />
      {id?.toLowerCase() !== 'bbb' && (
        <>
          <Rating
            rating={rating}
            totalStars={totalStars}
            totalReviews={totalReviews}
            filledStarColor={color}
          />
          <div className="mt-1 text-center">
            <Typography
              className="font-bold"
              component="span"
              variant="bodySmall"
            >
              {rating.toFixed(1)} |{' '}
            </Typography>
            <Typography
              className="font-bold"
              component="span"
              variant="bodySmall"
            >
              {numberFormat(totalReviews)}{' '}
            </Typography>
            <Typography component="span" variant="bodySmall">
              reviews
            </Typography>
          </div>
        </>
      )}
    </div>
  )
}

export default DynamicMarkers
